"use client";

import { useEffect, useState } from "react";
import FractalBackground from "@/components/fractalBackground";
import { animateMisc } from "@/utils/drawing";
import bezier from "bezier-easing";

export default function AnimatedFractalBackground({
	duration = 1300,
}: {
	duration?: number;
}) {
	const [frames, setFrames] = useState(0);
	useEffect(() => {
		let animate = true;
		(async () => {
			while (animate)
				await animateMisc(
					(p) => setFrames(1600 * p),
					duration,
					bezier(0.5, 0, 0.5, 1)
				);
		})();

		return () => {
			animate = false;
		};
	}, []);

	return <FractalBackground frames={frames} onlyDrawLast={400} />;
}

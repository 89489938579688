"use client";

import { calcEP, clearCanv, degToRad, drawLine } from "@/utils/drawing";
import { useRef, useEffect } from "react";

const pxPerFrame = 1;
const lineSegmentSize = 100;
const onSplitAngleRads = degToRad(45);
const maxFrames = 1600;

const FractalBackground = ({
	frames,
	onlyDrawLast,
}: {
	frames: number;
	onlyDrawLast: number;
}) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (canvasRef.current) {
			let ctx: CanvasRenderingContext2D;
			const start = {
				get x() {
					return (canvasRef.current?.width as number) / 2;
				},
				get y() {
					return (canvasRef.current?.height as number) / 2;
				},
			};

			const renderFrames = (frames: number) => {
				if (!canvasRef.current) return;
				frames = Math.min(frames, maxFrames);

				function continueFrom(
					x: number,
					y: number,
					angleRads: number,
					remainingDistance: number,
					leftTurns: number,
					rightTurns: number
				): boolean {
					const shouldDraw = remainingDistance < onlyDrawLast;

					const d = Math.min(remainingDistance, lineSegmentSize),
						[endX, endY] = shouldDraw
							? drawLine(ctx, x, y, angleRads, d)
							: calcEP(x, y, angleRads, d);

					let endInBounds = !!(
						endX &&
						endX < (canvasRef.current?.width as number) &&
						endY &&
						endY < (canvasRef.current?.height as number)
					);
					if (remainingDistance > lineSegmentSize && endInBounds) {
						let [r, l] = [true, true];
						if (rightTurns < 2)
							r = continueFrom(
								endX,
								endY,
								angleRads + onSplitAngleRads,
								remainingDistance - lineSegmentSize,
								+(leftTurns == 2),
								rightTurns + 1
							);
						if (leftTurns < 2)
							l = continueFrom(
								endX,
								endY,
								angleRads - onSplitAngleRads,
								remainingDistance - lineSegmentSize,
								leftTurns + 1,
								+(rightTurns == 2)
							);
						endInBounds = endInBounds && r && l;
					}
					return endInBounds;
				}

				const distanceToDraw = pxPerFrame * frames;
				// renderDrawLogo(1)
				clearCanv(canvasRef.current, ctx);
				ctx.beginPath();
				let directionsInBounds = [
					continueFrom(start.x, start.y, degToRad(-90), distanceToDraw, 0, 0), //up
					continueFrom(start.x, start.y, degToRad(90), distanceToDraw, 0, 0), //down

					continueFrom(start.x, start.y, 0, distanceToDraw, 0, 0), //right
					continueFrom(start.x, start.y, degToRad(180), distanceToDraw, 0, 0), //left
				];
				ctx.stroke();
				return directionsInBounds.every((inB) => !inB);
			};

			const setCanvasBounds = () => {
				if (canvasRef.current) {
					// get canvas's bounds
					const bounds = canvasRef.current.getBoundingClientRect();
					canvasRef.current.width = bounds.width;
					canvasRef.current.height = bounds.height;

					ctx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D;

					ctx.strokeStyle = "white";

					// renderDrawLogo(.9)
					// animateDrawLogo()
					renderFrames(frames);
				}
			};
			setCanvasBounds();

			addEventListener("resize", () => setCanvasBounds());

			renderFrames(frames);
		}
	}, [frames, canvasRef]);

	return <canvas ref={canvasRef} className="w-full h-full" />;
};

export default FractalBackground;

import { EasingFunction } from "bezier-easing";

export function drawLine(
	ctx: CanvasRenderingContext2D,
	sx: number,
	sy: number,
	a: number,
	d: number,
	isStart = true,
	duplicateLinesToIgnore: string[] = []
): [number, number, boolean] {
	const [ex, ey] = calcEP(sx, sy, a, d);
	const thisLine = [sx, sy, ex, ey].join(),
		isDupe = duplicateLinesToIgnore.includes(thisLine);
	if (isDupe) ctx.moveTo(ex, ey);
	else {
		drawLineTo(ctx, sx, sy, ex, ey, isStart);
		duplicateLinesToIgnore.push(thisLine);
	}

	return [ex, ey, isDupe];
}

export function drawLineTo(
	ctx: CanvasRenderingContext2D,
	sx: number,
	sy: number,
	ex: number,
	ey: number,
	isStart = true
) {
	if (isStart) ctx.moveTo(sx, sy);
	ctx.lineTo(ex, ey);
}

export function drawRandSpikeLine(
	ctx: CanvasRenderingContext2D,
	sx: number,
	sy: number,
	a: number,
	d: number,
	isStart = true,
	seed = Math.random,
	minSegmentDist = 5,
	maxSegmentDist = 10,
	spikeMaxHeight = 30
) {
	const [ex, ey] = calcEP(sx, sy, a, d),
		xDist = ex - sx;

	let [x, y] = [sx, sy];
	for (let rxDist = xDist; rxDist; ) {
		const cSegDist = Math.min(
				rxDist,
				minSegmentDist + seed() * (maxSegmentDist - minSegmentDist)
			),
			p = rxDist / xDist;
		let nx = x + cSegDist,
			ny =
				sy +
				(xDist - rxDist) * Math.tan(a) +
				(seed() * spikeMaxHeight - spikeMaxHeight / 2);
		rxDist -= cSegDist;
		if (rxDist === 0) [nx, ny] = [ex, ey];
		drawLineTo(ctx, x, y, nx, ny, isStart);
		isStart = false;
		[x, y] = [nx, ny];
	}

	return [ex, ey];
}

export function calcEP(sx: number, sy: number, a: number, d: number) {
	return [sx + d * Math.cos(a), sy + d * Math.sin(a)];
}

export function clearCanv(
	canvas: HTMLCanvasElement,
	ctx: CanvasRenderingContext2D
) {
	ctx.clearRect(0, 0, canvas.width as number, canvas.height as number);
}

export function degToRad(rads: number) {
	return rads * (Math.PI / 180);
}

export interface BoxMeasurements {
	width: number;
	height: number;
}

export function optimizeForDPI(
	canvas: HTMLCanvasElement,
	ctx: CanvasRenderingContext2D
): BoxMeasurements {
	const rect = canvas.getBoundingClientRect();

	// increase the actual size of our canvas
	canvas.width = rect.width * devicePixelRatio;
	canvas.height = rect.height * devicePixelRatio;

	// ensure all drawing operations are scaled
	ctx.scale(devicePixelRatio, devicePixelRatio);

	// scale everything down using CSS
	canvas.style.width = rect.width + "px";
	canvas.style.height = rect.height + "px";

	// return rect
	return {
		width: rect.width,
		height: rect.height,
	};
}

export async function sleep(ms: number) {
	await new Promise<void>((r) => setTimeout(r, ms));
}

export function randIntN(n: number) {
	return Math.floor(Math.random() * n);
}

export function calcInRadius(circumradius: number, sides: number) {
	return (circumradius * Math.sin(Math.PI / sides)) / 2;
}

export function animateStyling(
	element: Element,
	animateTo: Keyframe,
	opts: KeyframeAnimationOptions
) {
	const oldStyles = element.getAttribute("style");

	return new Promise<Function>((setUndo) => {
		console.log("animating", animateTo);
		const anim = element.animate([{}, animateTo], opts);

		let thisListener: EventListener;
		anim.addEventListener(
			"finish",
			(thisListener = () => {
				anim.removeEventListener("finish", thisListener);

				console.log(22, keyframeToStyleString(animateTo));
				element.setAttribute(
					"style",
					(oldStyles || "") + ";" + keyframeToStyleString(animateTo)
				);

				setUndo(() => {
					anim.reverse();
					anim.play();

					setTimeout(() => {
						if (oldStyles) element.setAttribute("style", oldStyles);
						else element.removeAttribute("style");
					}, 20);
					return new Promise<void>((done) => {
						anim.addEventListener("finish", () => {
							done();
						});
					});
				});
			})
		);
	});
}

export function setElementStyle(element: Element, newStyles: Keyframe) {
	element.setAttribute("style", keyframeToStyleString(newStyles));
}

export function keyframeToStyleString(style: Keyframe) {
	let str = "";

	Object.keys(style).forEach((prop) => {
		if (prop[0] === prop[0].toUpperCase() && prop[0] !== "-") str += "-";
		str += `${prop
			.split(/\.?(?=[A-Z])/)
			.join("-")
			.toLowerCase()}: ${style[prop]};`;
	});

	return str;
}

export function calcInsetTriangleCircumradius(
	parentCircumradius: number,
	relativeRotDeg: number
) {
	const insetCircumRadius =
		parentCircumradius *
		(Math.sin(degToRad(30)) / Math.sin(degToRad(150 - relativeRotDeg)));

	return insetCircumRadius;
}

export function calcOutsetTriangleCircumradius(
	childCircumradius: number,
	relativeRotDeg: number
) {
	// b = c·sin(B)/sin(C) = 29.99543
	return (
		childCircumradius *
		(Math.sin(degToRad(150 - relativeRotDeg)) / Math.sin(degToRad(30)))
	);
}

export function animateMisc(
	onFrame: (percent: number) => void,
	durationMS: number,
	easing: EasingFunction = (p) => p,
	cancelSignal?: AbortSignal
) {
	return new Promise<void>((done) => {
		const sTime = performance.now();
		let animationFunc: FrameRequestCallback;

		requestAnimationFrame(
			(animationFunc = (now) => {
				if (cancelSignal?.aborted) return done();

				const elapsedMS = now - sTime;
				const p = Math.min(elapsedMS / durationMS, 1);
				onFrame(easing(p));
				if (p !== 1) requestAnimationFrame(animationFunc);
				else done();
			})
		);
	});
}

export function onIntersect(
	element: Element,
	callback: Function,
	opts?: IntersectionObserverInit,
	cleanup?: Function
) {
	const observer = new IntersectionObserver(async ([e]) => {
		if (e.isIntersecting) {
			callback();
		}
	}, opts);
	observer.observe(element);
	return () => {
		observer.disconnect();
		cleanup?.();
	};
}
